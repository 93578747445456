<template>
  <div>
    <!-- First Row -->
    <b-row>
      <b-col cols="12" lg="12">
        <b-card v-if="cohortData.id">
          <b-row>
            <b-col>
              <b-link :to="{ name: 'apps-accounting-receivable-cohort-view', params: { id: cohortData.id } }">
                <h3>
                  <b-badge variant="primary" pill>{{ title(mode) }}</b-badge>
                  {{ cohortData.name }}
                </h3>
              </b-link>
            </b-col>
          </b-row>
        </b-card>

        <b-card class="card-account">
          <b-row class="align-items-center">
            <b-col v-if="mode === 'import'" lg="3">
              <b-form-file size="sm" class="form-control form-control-file" type="file" @change="onFileSelected">
                Select File
              </b-form-file>
            </b-col>

            <b-col v-if="mode === 'import'" lg="2">
              <b-button variant="outline-primary" size="sm" block @click="() => loadWorkbook()"> Load Blank </b-button>
            </b-col>

            <b-col v-if="cohortId && mode !== 'import'" lg="2">
              <b-button variant="outline-info" size="sm" block @click="() => loadWorkbook('example')">
                Load Existing Data
              </b-button>
            </b-col>

            <b-col v-if="cohortId && mode === 'edit'" lg="2">
              <b-button variant="primary" size="sm" block @click="() => saveWorkbook('update')">
                Save Only Existing
              </b-button>
            </b-col>

            <b-col v-if="cohortId && mode === 'edit'" lg="2">
              <b-button variant="primary" size="sm" block @click="() => saveWorkbook('insert')"> Save Only New </b-button>
            </b-col>

            <b-col v-if="mode === 'edit'" lg="2">
              <b-button variant="success" size="sm" block @click="() => saveWorkbook('upsert')"> Save / Create </b-button>
            </b-col>

            <b-col v-if="!cohortId && mode === 'import'" lg="2">
              <b-button variant="primary" size="sm" block @click="() => createWorkbook(cohortData, 'import')">
                Create Cohort
              </b-button>
            </b-col>
            <b-col v-if="cohortId && mode === 'import'" lg="2">
              <b-button variant="primary" size="sm" block @click="() => loadWorkbook('example')"> Import Accounts </b-button>
            </b-col> 
            <b-col v-if="$can('integrations.monday.export.session')">
              <b-form-group label="SSN Visibility" v-slot="{ ariaDescribedby }">
                <b-form-radio-group
                  id="btn-radios-1"
                  v-model="opts.ssn"
                  :options="buttons"
                  buttons
                  size="sm"
                  variant="info"
                  class="mb-0"
                ></b-form-radio-group>
              </b-form-group>
            </b-col>
          </b-row>
        </b-card>
      </b-col>
      <b-col cols="12" lg="12">
        <div id="x-spreadsheet"></div>
        <div id="luckysheet" style=""></div>
      </b-col>
    </b-row>
  </div>
</template>

<style>
#luckysheet {
  min-width: 450px;
  min-height: 650px !important;
  margin: 0px;
  padding: 0px;
  width: 100%;
  height: 100%;
  left: 0px;
  top: 0px;
}

.luckysheet-share-logo {
  display: none;
}
</style>

<script>
import store from '@/store';
import router from '@/router';
import { ref, onUnmounted, onMounted, watch } from '@vue/composition-api';
import {
  BRow,
  BCol,
  BAlert,
  BLink,
  BTable,
  BButtonGroup,
  BFormGroup,
  BFormRadioGroup,
  BFormInput,
  BButton,
  BFormFile,
  BCard,
  BMedia,
  BAvatar,
  BBadge,
  BDropdown,
  BDropdownItem,
  BPagination,
  BDropdownDivider,
} from 'bootstrap-vue';
import vSelect from 'vue-select';
import receivableStoreModule from '../receivableStoreModule';
import CohortAccountList from '@/views/apps/accounting/receivable/cohort-view/CohortAccountList';
import CohortStatistics from '@/views/apps/accounting/receivable/cohort-view/CohortStatistics';
import useAccountsList from '@/views/apps/accounting/receivable/account-list/useAccountsList';
import LuckyExcel from 'luckyexcel';
import axiosIns from '@/libs/axios';
import { title } from '@/@core/utils/filter';

export default {
  components: {
    CohortStatistics,
    CohortAccountList,
    BRow,
    BFormFile,
    BButtonGroup,
    BFormGroup,
    BFormRadioGroup,
    BCol,
    BAlert,
    BLink,
    BTable,

    BCard,
    BFormInput,
    BButton,
    BMedia,
    BAvatar,
    BBadge,
    BDropdown,
    BDropdownItem,
    BPagination,
    BDropdownDivider,

    vSelect,
  },
  data: () => {
    return {
            buttons: [
        { value: 'hidden', text: 'Hidden', state: true },
        { value: 'masked', text: 'Masked', state: false, disabled: true },
        { value: 'visible', text: 'Visible', state: false },
      ],
    };
  },
  computed: {
    btnStates() {
      return this.buttons.map((btn) => btn.state);
    },
  },
  methods: {
    createWorkbook(cohortData, mode) {},
    saveWorkbook(mode) {
      // insert update upsert

      const worksheet = window.luckysheet.toJson();

      console.log(`saving workbook in mode ${mode}`, worksheet);

      store
        .dispatch('app-accounting-receivable/modifySheet', { workbook: worksheet, mode })
        .then((response) => {
          let { data } = response;
          console.log(`result from modify sheet`, data);

          this.loadWorkbook();
        })
        .catch((error) => {
          if (error.response.status === 404) {
            cohortData.value = {};
          }
        });
    },
    saveToXlsx() {
      console.log(`saving to xlsx`);
      try {
        LuckyExcel.transformLuckyToExcel(
          window.luckysheet,
          (cb) => {
            console.log(cb);
          },
          (err) => {
            console.error(err);
          }
        );
      } catch (e) {
        console.error(`failed to save xlsx`, e);
      }
    },
    onFileSelected(evt) {
      const files = evt.target.files;
      if (files == null || files.length == 0) {
        alert('No files wait for import');
        return;
      }

      let name = files[0].name;
      let suffixArr = name.split('.'),
        suffix = suffixArr[suffixArr.length - 1];
      if (suffix != 'xlsx') {
        alert('Currently only supports the import of xlsx files');
        return;
      }

      LuckyExcel.transformExcelToLucky(files[0], function (exportJson, luckysheetfile) {
        if (exportJson.sheets == null || exportJson.sheets.length == 0) {
          alert('Failed to read the content of the excel file, currently does not support xls files!');
          return;
        }

        console.log(exportJson);

        const { sheets } = exportJson;

        if (sheets.length === 0) {
          return alert('no sheets found');
        }

        window.luckysheet.destroy();

        window.luckysheet.create({
          container: 'luckysheet', //luckysheet is the container id
          showinfobar: false,
          data: sheets,
        });

        const { data } = window.luckysheet.getSheet();

        const header = data[0];
        for (let colKey in header) {
          const col = header[colKey];
          console.log(col);

          // M is display
          if (col.m === 'Student Email') {
            col.m = 'Student Email#2';
          }
          // V is raw
          if (col.v === 'Student Email') {
            col.v = 'Student Email #1';
          }

          data[0][colKey] = col;
        }

        for (let i = 1; i < data.length; i++) {
          const col = data[i];
        }
      });
    },
  },
  setup() {
    const cohortSummary = ref({ cohort: {}, meta: {} });

    const COHORT_APP_STORE_MODULE_NAME = 'app-accounting-receivable';

    // Register module
    if (!store.hasModule(COHORT_APP_STORE_MODULE_NAME))
      store.registerModule(COHORT_APP_STORE_MODULE_NAME, receivableStoreModule);

    // UnRegister on leave
    onUnmounted(() => {
      store.commit('verticalMenu/UPDATE_VERTICAL_MENU_COLLAPSED', false);
      //fixme: zoom disable document.body.style.zoom = '90%';

      if (store.hasModule(COHORT_APP_STORE_MODULE_NAME)) store.unregisterModule(COHORT_APP_STORE_MODULE_NAME);
    });

    // import, export, edit
    const mode = ref(router.currentRoute.query.mode || 'import');
    const cohortId = ref(router.currentRoute.query.cohort);
    const cohortData = ref({});

    if (cohortId.value) {
      store
        .dispatch('app-accounting-receivable/fetchCohort', { id: cohortId.value })
        .then((response) => {
          let { data } = response;
          cohortData.value = data;
        })
        .catch((error) => {
          if (error.response.status === 404) {
            cohortData.value = {};
          }
        });
    }

    //     const loadWorkbook = async (override = undefined) => {
    //       //Configuration item
    //       var options = {
    //         view: {
    //           height: () => document.getElementById('x-spreadsheet').clientHeight,
    //           width: () => document.getElementById('x-spreadsheet').clientWidth,
    //         },
    //         // row: {
    //         //   len: 100,
    //         //   height: 25
    //         // },
    //         // col: {
    //         //   len: 26,
    //         //   width: 100,
    //         //   indexWidth: 60,
    //         //   minWidth: 60
    //         // }
    //       };
    //
    //       const params = { cohort: router.currentRoute.query.cohort };
    //
    //       if (override !== 'example' && mode.value === 'import') {
    //         delete params.cohort;
    //       }
    //
    //       store
    //         .dispatch('app-accounting-receivable/fetchSheet', { params })
    //         .then((response) => {
    //           // options.data.push(response.data);
    //
    //           const rows10 = { len: 1000 };
    //           for (let i = 0; i < 1000; i += 1) {
    //             rows10[i] = {
    //               cells: {
    //                 0: { text: 'A-' + i },
    //                 1: { text: 'B-' + i },
    //                 2: { text: 'C-' + i },
    //                 3: { text: 'D-' + i },
    //                 4: { text: 'E-' + i },
    //                 5: { text: 'F-' + i }
    //               }
    //             };
    //           }
    //
    //
    //           const rows = {
    //             len: 80,
    //             1: {
    //               cells: {
    //                 0: { text: 'testingtesttestetst' },
    //                 2: { text: 'testing' }
    //               }
    //             },
    //             2: {
    //               cells: {
    //                 0: { text: 'render', style: 0 },
    //                 1: { text: 'Hello' },
    //                 2: { text: 'haha', merge: [1, 1] }
    //               }
    //             },
    //             8: {
    //               cells: {
    //                 8: { text: 'border test', style: 0 }
    //               }
    //             }
    //           };
    //
    //           const s = x_spreadsheet('#x-spreadsheet', options)
    //             .loadData([
    //               {
    //                 freeze: 'A1',
    //                 styles: [
    //                   {
    //                     bgcolor: '#000',
    //                     textwrap: true
    //                   }
    //                 ],
    //
    //                 merges: [
    //                   'C3:D4'
    //                 ],
    //                 cols: {
    //                   len: 10,
    //                   2: { width: 200 }
    //                 },
    //                 rows
    //               },
    //               {
    //                 name: 'sheet 1',
    //                 rows: rows10
    //               }
    //             ]) // load data
    //             .change(data => {
    //               // save data to db
    //               console.log(`data changed`, data);
    //             });
    //
    // // data validation
    //           s.validate();
    //           //
    //           // luckysheet.create(options);
    //           // luckysheet.setHorizontalFrozen(true, { range: 'A1' });
    //
    //         })
    //         .catch(err => {
    //           console.error(err);
    //         });
    //
    //     };

    const loadWorkbook = async (override = undefined) => {
      //Configuration item
      var options = {
        container: 'luckysheet', //luckysheet is the container id
        showinfobar: false,
        data: [],
      };

      // const sheet2 = await loadSheet2();
      // const z = await loadTable();

      const q = router.currentRoute.query;

      const params = { cohort: router.currentRoute.query.cohort, filter: {}, ssn: opts.value.ssn };

      if (override !== 'example' && mode.value === 'import') {
        delete params.cohort;
      }

      if (q.paymentDateFilter) {
        const [s, e] = q.paymentDateFilter.split(',');
        if (!params.filter.payments) params.filter.payments = { date: {} };
        if (s) params.filter.payments.date.start = s;
        if (e) params.filter.payments.date.end = e;
      }
      if (q.search) params.filter.search = q.search;
      if (q.status) params.filter.status = q.status;
      if (q['profile.attributes.fundingSource']) params.filter.fundingSource = q['profile.attributes.fundingSource'];

      if (q.mode) params.mode = q.mode;

      store
        .dispatch('app-accounting-receivable/fetchSheet', { params })
        .then((response) => {
          options.data.push(response.data);

          luckysheet.create(options);
          luckysheet.setHorizontalFrozen(true, { range: 'A1' });
        })
        .catch((err) => {
          console.error(err);
        });
    };

    onMounted(async () => {
      store.commit('verticalMenu/UPDATE_VERTICAL_MENU_COLLAPSED', true);
      //fixme: zoom disable  document.body.style.zoom = '100%';

      loadWorkbook();

      return {};
    });

    onUnmounted(() => {
      luckysheet.destroy();
    });

    const opts = ref({
      ssn: 'hidden',
    })

    watch(opts.value, () => {
      console.log(`updated`)
      loadWorkbook()
    })

    return { title, loadWorkbook, mode, cohortId, cohortData, opts };
  },
};
</script>
